import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, catchError, concatMap, map, Observable, of, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IResTrainingDetailGet, IResTrainingGet, ITrainingGet } from '../interfaces/training-get.interface';
import { IDomainGet } from '../../common/interfaces/domain-get.interface';
import { ICityGet } from '../../common/interfaces/city-get.interface';
import { IInstitutionGet } from '../../institutions/interfaces/institution-get.interface';
import { InstitutionsService } from '../../institutions/services/institutions.service';
import { IGradeGet } from '../../common/interfaces/grade-get.interface';
import { IDurationGet } from '../../common/interfaces/duration-get.interface';
import { ICategoryGet} from '../../common/interfaces/category-get.interface';
import { IGoalGet } from '../../common/interfaces/goal-get.interfaces';
import { IDiplomaGet } from '../../common/interfaces/diploma-get.inteface';
import { CommonService } from '../../common/services/common.service';

@Injectable({
  providedIn: 'root'
})
export class TrainingService {

  findTrainingsByTrainings(educationalPrograms: ITrainingGet[]): Observable<ITrainingGet[]> {
    console.log("start matchTrainings : ", educationalPrograms);
  
    return this.fetchAllTraining().pipe(
      map(res => {
        const matchTrainings = educationalPrograms
          .map(training => res.data.find(resp => resp.id === training.id)) // Trouve les correspondances
          .filter(resp => resp !== undefined) as ITrainingGet[]; // Supprime les `undefined` et cast en `ITrainingGet[]`
        
        console.log("matchTrainings : ", matchTrainings);
        return matchTrainings;
      })
    );
  }
  
  

  private readonly _trainings$ = new BehaviorSubject<IResTrainingGet>(null);
  get trainings$(): Observable<IResTrainingGet> {
    return this._trainings$.asObservable()
  }

  private _allFilterTrainings: ITrainingGet[];
  private readonly _allTrainings$ = new BehaviorSubject<ITrainingGet[]>([]);
  get allTraining$(): Observable<ITrainingGet[]> {
    return this._allTrainings$.asObservable()
  }

  private readonly _allFiltersTraining$ = new BehaviorSubject<ITrainingGet[]>([])
  get allFiltersTrainings$(): Observable<ITrainingGet[]> {
    return this._allFiltersTraining$.asObservable()
  }

  private lastTrainingsLoad = 0;
  private totaItems = 0

  private readonly _institutionService = inject(InstitutionsService)
  private readonly apiURL = environment.baseUrl;
  private readonly _http = inject(HttpClient)
  private readonly _commonService = inject(CommonService)

  domaines$: Observable<IDomainGet[]>
  domaines: IDomainGet[]


  constructor() {
    this.domaines$ = this._commonService.domaines$
    this._commonService.getAllDomain("program").subscribe()
    this.domaines$.pipe(
      tap(res => {
        this.domaines = res
      })
    )
      .subscribe()
  }


  /**
  * Retrieve all list of training   
  * 
  * @param {number}
  * @returns {Observable<IResTrainingGet>}
  */
  public getAllTraining(page: number = 0, limit: number = 15): Observable<IResTrainingGet> {
    const params = new HttpParams()
      .set('page', page)
      .set('limit', limit);

    return this._http.get<IResTrainingGet>(`${this.apiURL}/educational-programs`, { params }).pipe(
      tap(res => {
        this.totaItems = res.totalItems
        // this._http.get<IResTrainingGet>(`${this.apiURL}/educational-program/get-all?limit=${this.totaItems}`).subscribe(resp => {
        //   this._allTrainings$.next(resp.data)
        // })
        return res
      }),
      concatMap((res) => {
        console.log("time: ", this.lastTrainingsLoad);

        if (this.lastTrainingsLoad > 0) {
          this._allTrainings$.subscribe(res => {
            this._allFilterTrainings = res
          })
          return of(res);
        } else if (this.totaItems > limit) {
          // Make a second call to retrieve all establishments
          return this._http.get<IResTrainingGet>(`${this.apiURL}/educational-programs?page=0&limit=${this.totaItems}`).pipe(
            tap((resp) => {
              this.lastTrainingsLoad = Date.now();
              console.log("Last: ", resp);
              console.log("time: ", this.lastTrainingsLoad);
              this._trainings$.next(resp)
              this._allTrainings$.next(resp.data);
              this._allFiltersTraining$.next(resp.data)
              this._allFilterTrainings = resp.data;
            }),
            // Return the original response from the first query
            map(() => res)
          );
        }
      }),
      catchError(error => {
        console.error('Erreur lors de la récupération des trainings :', error);
        throw error;
      })
    );
  }


  /**
   * Retrieve alll list of institution   
   * 
   * @param {number}
   * @returns {Observable<IResCareerGet>}
   */
  public fetchAllTraining(limit: number = 10000): Observable<IResTrainingGet> {

    if (this.lastTrainingsLoad > 0) {

      return this.trainings$;

    } else {
      this.totaItems = limit
      return this._http.get<IResTrainingGet>(`${this.apiURL}/educational-programs?page=0&limit=${this.totaItems}`).pipe(
        tap((response) => {
          this.lastTrainingsLoad = Date.now();
          console.log("time: ", this.lastTrainingsLoad);
          this._trainings$.next(response)
          this._allTrainings$.next(response.data);
          this._allFilterTrainings = response.data;
        }),
      );
    }
  }

  /**
   * Search the training by keyword
   *
   *  @param {string}
   * @returns {ITrainingGet[]; ICityGet[] }
   */
  public searchTraining(word: string): { filterTrainings: ITrainingGet[], filterCity: ICityGet[] } {
    let filterTrainings: ITrainingGet[] = [];
    let filterCity: ICityGet[] = [];

    this.allTraining$.subscribe((res) => {
      res.forEach((item) => {
        const normalizedName = item?.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase().trim();
        const normalizedWord = word.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase().trim();
        if (normalizedName.includes(normalizedWord)) {
          filterTrainings.push(item)
        }
      });
    });

    return { filterTrainings, filterCity };
  }

  /**
    * Retrieve one training by ID
    *
    * @param {string}
    * @returns { Observable<ITrainingDetailGet> }
    */
  public getOneTraining(idTraining: string): Observable<ITrainingGet> {
    return this._http.get<IResTrainingDetailGet>(`${this.apiURL}/educational-programs/${idTraining}`).pipe(
      map(response => {
        return response.data
      }),
      catchError(error => {
        console.error('Erreur lors de la récupération du metier :', error);
        throw error;
      })
    );
  }

  /**
     * Retrieve trainings by Filter ID
     *
     * @param {string}
     * @returns { Observable<IResTrainingGet> }
     */
  public getAllTrainingMultiFilters(ids: string[], page: number = 0, limit: number = 15): Observable<IResTrainingGet> {
    const params = new HttpParams()
      .set('page', page)
      .set('limit', limit);
    return this._http.get<IResTrainingGet>(`${this.apiURL}/educational-programs/search/multi?ids=${ids}`, { params }).pipe(
      tap((res) => {
        this.totaItems = res.totalItems;
        console.log("First 1: ", res);
      }),
      concatMap((res) => {
        return this._http.get<IResTrainingGet>(`${this.apiURL}/educational-programs/search/multi?ids=${ids}&page=0&limit=${this.totaItems}`).pipe(
          tap(resp => {
            console.log("Last 1", resp);
            this._allFilterTrainings = resp.data
            this._allFiltersTraining$.next(resp.data)
          }),
          // Retourner la réponse originale de la première requête
          map(() => res)
        )
      }),
      catchError(error => {
        console.error('Erreur lors de la récupération des trainings :', error);
        throw error;
      })
    );
  }


  /**
    * Add city to training list
    *
    * @param {IInstitutionDetailGet}
    * @returns { IGrade[] }
    */
  public addCityToTrainingList(trainings: ITrainingGet[]) {


    // console.log("Add start  : ", trainings);


    let resultTrainings: ITrainingGet[] = trainings

    trainings?.forEach((training, index) => {
      let macthInstitution: IInstitutionGet
      this._institutionService.institutions$.pipe(
        map(institutions => {
          // console.log("Add middle : ", institutions);
          macthInstitution = institutions?.data.find(institution => institution?.id == training?.institutions[0]?.id)
        })
      )
        .subscribe()

      resultTrainings[index].institutions[0] = macthInstitution
    });

    // console.log("Add end : ", resultTrainings);

    return resultTrainings

  }

  /**
     * Sort filter items 
     *
     * @param {IInstitutionGet[]}
     * @returns { IDomainGet[]; IInterestGet[]; IGradeGet[] }
     */
  public filterElements(trainings: ITrainingGet[]): { filterDomaines: IDomainGet[], filterTypeInstitution: ICategoryGet[], filterGoal: IGoalGet[], filterRequiredGrades: IGradeGet[], filterObtainedDiplomas: IDiplomaGet[], filterDurations: IDurationGet[], filterCity: ICityGet[] } {
    let filterDomaines: IDomainGet[] = [];
    let filterTypeInstitution: ICategoryGet[] = [];
    let filterGoal: IGoalGet[] = [];
    let filterRequiredGrades: IGradeGet[] = [];
    let filterObtainedDiplomas: IDiplomaGet[] = [];
    let filterDurations: IDurationGet[] = [];
    let filterCity: ICityGet[] = []

    this._allFilterTrainings = this.addCityToTrainingList(this._allFilterTrainings)

    this._allFilterTrainings?.forEach((item) => {

      let domains = item?.domains || [];
      domains = domains.map(domain => {
        return this.domaines.find(res => res.id == domain.id)
      })
      const category = item?.institutions[0]?.category;
      const goal = item?.goal;
      const requiredGrade = item?.requiredGrades;
      const obtainedDiplomas = item?.obtainedDiplomas;
      const duration = item?.duration;
      const city = item?.institutions[0]?.city;

      // Filtrage des categories
      if (category && !filterTypeInstitution.some(resp => resp?.id === category?.id)) {
        filterTypeInstitution.push(category);
      }

      // Filtrage des objectifs
      goal?.forEach(goal => {
        if (!filterGoal.some(resp => resp?.id === goal?.id)) {
          filterGoal.push(goal);
        }
        // });
      });

      // Filtrage des niveau requis
      requiredGrade?.forEach(requiredGrade => {
        if (!filterRequiredGrades.some(resp => resp?.id === requiredGrade?.id)) {
          filterRequiredGrades.push(requiredGrade);
        }
        // });
      });

      // Filtrage des niveau vise
      obtainedDiplomas?.forEach(obtainedDiploma => {
        if (!filterObtainedDiplomas.some(resp => resp?.id === obtainedDiploma?.id)) {
          filterObtainedDiplomas.push( obtainedDiploma);
        }
        // });
      });

      // Filtrage des duree
      if (duration && !filterDurations.some(resp => resp?.id === duration?.id)) {
        filterDurations.push( duration);
      }

      // Filtrage des villes
      if (city && !filterCity.some(resp => resp?.id === city?.id)) {
        filterCity.push(city);
      }

      // Filtrage des domaines
      domains?.forEach(domain => {
        domain = domain?.parent ? domain?.parent : domain
        if (!filterDomaines.some(resp => resp?.id === domain?.id)) {
          filterDomaines.push(domain);
        }
        // });
      });
    });

    return { filterDomaines, filterTypeInstitution, filterGoal, filterRequiredGrades, filterObtainedDiplomas, filterDurations, filterCity };
  }


}
