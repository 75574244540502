import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, tap, catchError, BehaviorSubject, of, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ICategoryGet, IResCategoryGet } from '../interfaces/category-get.interface';
import { ICityGet, IResCityGet } from '../interfaces/city-get.interface';
import { IDiplomaGet, IResDiplomaGet } from '../interfaces/diploma-get.inteface';
import { IDurationGet, IResDurationGet } from '../interfaces/duration-get.interface';
import { IDomainDetailGet, IDomainGet, IResDomainGet } from '../interfaces/domain-get.interface';
import { IInterestGet, IResInterestGet } from '../interfaces/interest-get.interface';
import { inject, Injectable } from '@angular/core';
import { IGoalGet, IResGoalGet } from '../interfaces/goal-get.interfaces';
import { IGradeGet, IResGradeGet } from '../interfaces/grade-get.interface';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  private readonly apiURL = environment.baseUrl;
  private readonly _http = inject(HttpClient)

  private readonly _domaines$ = new BehaviorSubject<IDomainGet[]>([]);
  get domaines$(): Observable<IDomainGet[]> {
    return this._domaines$.asObservable();
  }
  private lastDomaineLoad = 0;
  
  private readonly _categories$ = new BehaviorSubject<ICategoryGet[]>([]);
  get categories$(): Observable<ICategoryGet[]> {
    return this._categories$.asObservable();
  }
  private lastCategoriesLoad = 0;

  private readonly _cities$ = new BehaviorSubject<ICityGet[]>([]);
  get cities$(): Observable<ICityGet[]> {
    return this._cities$.asObservable();
  }
  private lastCitiesLoad = 0;

  private readonly _status$ = new BehaviorSubject<any[]>([]);
  get status$(): Observable<any[]> {
    return this._status$.asObservable();
  }
  private lastStatusLoad = 0;

  private readonly _requiredGrades$ = new BehaviorSubject<IGradeGet[]>([]);
  get requiredGrades$(): Observable<any[]> {
    return this._requiredGrades$.asObservable();
  }
  private lastRequiredGradesLoad = 0;

  private readonly _obtainedGrades$ = new BehaviorSubject<IGradeGet[]>([]);
  get obtainedGrades$(): Observable<any[]> {
    return this._obtainedGrades$.asObservable();
  }
  private lastObtainedGradesLoad = 0;

  private readonly _obtainedDilomas$ = new BehaviorSubject<IDiplomaGet[]>([]);
  get obtainedDilomas$(): Observable<any[]> {
    return this._obtainedDilomas$.asObservable();
  }
  private lastObtainedDilomasLoad = 0;

  private readonly _goals$ = new BehaviorSubject<IGoalGet[]>([]);
  get goals$(): Observable<any[]> {
    return this._goals$.asObservable();
  }
  private lastGoalsLoad = 0;

  private readonly _interests$ = new BehaviorSubject<IInterestGet[]>([]);
  get interests$(): Observable<any[]> {
    return this._interests$.asObservable();
  }
  private lastInterestsLoad = 0;

  private readonly _durations$ = new BehaviorSubject<IDurationGet[]>([]);
  get durations$(): Observable<any[]> {
    return this._durations$.asObservable();
  }
  private lastDurationsLoad = 0;



    /**
      * Retrieve the list of all domaines
      * 
      * @param {number, number}
      * @returns {IResDomainGet[]}
    */
    public getAllDomain(type: string,page = 0, limit = 2000): Observable<IResDomainGet> {
      if ((this.lastDomaineLoad > 0) && this._domaines$.value) {
        console.log("In service domaine");
        return of(null);
      } else {
        const params = new HttpParams().set("type", type).set("page", page).set("limit", limit);
        return this._http.get<IResDomainGet>(`${this.apiURL}/domains`, { params }).pipe(
            tap((response) => {
              this._domaines$.next(response.data);
              this.lastDomaineLoad = Date.now();
            }),
            catchError((error) => {
              console.error(
                "Erreur lors de la récupération des doamines :",
                error
              );
              throw error;
            })
        );
      }
      
    }
  
  
    public getOneDomain(idDomain: string): Observable<IDomainGet> {
      return this._http.get<IDomainDetailGet>(`${this.apiURL}/domains/${idDomain}`).pipe(
        map(res => {
          return res.data
        }),
        catchError(error => {
          console.error('Erreur lors de la récupération du domaine :', error);
          throw error;
        })
      );
    }
  
  
  


  /**
   * Retrieve list of all categories
   * 
   * @param {number, number}
   * @returns {ICategoryGet[]}
 */
  public getAllCategory(type: string, page = 0, limit = 2000): Observable<IResCategoryGet> {
    if ((this.lastCategoriesLoad > 0) && this._categories$.value) {
      return of();
    } else {
      const params = new HttpParams().set("page", page).set("limit", limit);
    return this._http.get<IResCategoryGet>(`${this.apiURL}/category/get-all`, { params }).pipe(
        tap((response) => {
          this._categories$.next(response.data.map(res => res.cat));
          this.lastCategoriesLoad = Date.now();
        }),
        catchError((error) => {
          console.error( "Erreur lors de la récupération des categories :", error );
          throw error;
        })
    );
    }
    
  }

  /**
    * Retrieve list of all city 
    * 
    * @param {number, number}
    * @returns {Observable<ICityGet[]>}
  */
  public getAllCity(page = 0, limit = 2000): Observable<IResCityGet> {
    if ((this.lastCitiesLoad > 0) && this._cities$.value) {
      return of();
    } else {
      const params = new HttpParams().set("page", page).set("limit", limit);
      return this._http.get<IResCityGet>(`${this.apiURL}/city/get-all`, { params }).pipe(
          tap((response) => {
            this._cities$.next(response.data);
            this.lastCitiesLoad = Date.now();
          }),
          catchError((error) => {
            console.error(
              "Erreur lors de la récupération des etablissements :",
              error
            );
            throw error;
          })
      );
    }
  }

  /**
   *Retrieve the list of all status
   *
   * @param {string}
   * @returns { Observable<any> }
   */
  public getAllStatut(page = 0, limit = 2000): Observable<any> {
    if ((this.lastStatusLoad > 0) && this._status$.value) {
      return of();
    } else {
      const params = new HttpParams().set("page", page).set("limit", limit);
      return this._http.get<any>(`${this.apiURL}/types`, { params }).pipe(
        tap((response) => {
          this._status$.next(response.data);
          this.lastStatusLoad = Date.now();
        }),
        catchError((error) => {
          console.error(
            "Erreur lors de la récupération des statuts :",
            error
          );
          throw error;
        })
      );
    }

  }

  /**
    * Retrieve list of all grade  
    * 
    * @param {number, number}
    * @returns {Observable<IGradeGet[]>}
  */
  public getAllGrade(page = 0, limit = 2000): Observable<IResGradeGet> {
    const params = new HttpParams().set("page", page).set("limit", limit);

    return this._http
      .get<IResGradeGet>(`${this.apiURL}/grade/all-grades`, { params })
      .pipe(
        tap((response) => {
         
        }),
        catchError((error) => {
          console.error(
            "Erreur lors de la récupération des etablissements :",
            error
          );
          throw error;
        })
      );
      
  }

  /**
  * Retrieve list of all obtained grade
  * 
  * @param {number, number}
  * @returns {Observable<IGradeGet[]>}
*/
  public getObtainedGrades(page = 0, limit = 2000): Observable<IResGradeGet> {
    if ((this.lastObtainedGradesLoad > 0) && this._obtainedGrades$.value) {
      return of();
    } else {
      const params = new HttpParams().set("page", page).set("limit", limit);
      return this._http
        .get<IResGradeGet>(`${this.apiURL}/grade/obtained-grades`, { params })
        .pipe(
          tap((response) => {
            this._obtainedGrades$.next(response.data);
            this.lastObtainedGradesLoad = Date.now();
          }),
          catchError((error) => {
            console.error(
              "Erreur lors de la récupération des etablissements :",
              error
            );
            throw error;
          })
        );
    }
    
  }

  /**
 * Retrieve list of all obtained grade
 * 
 * @param {number, number}
 * @returns {Observable<IGradeGet[]>}
*/
  public getObtainedDiplomas(page = 1, limit = 2000): Observable<IResDiplomaGet> {
    if ((this.lastObtainedDilomasLoad > 0) && this._obtainedDilomas$.value) {
      return of();
    } else {
    const params = new HttpParams().set("page", page).set("limit", limit);
    return this._http
      .get<IResDiplomaGet>(`${this.apiURL}/diplomas`, { params })
      .pipe(
        tap((response) => {
          this._obtainedDilomas$.next(response.data.map(res => res.diploma));
            this.lastObtainedDilomasLoad = Date.now();
        }),
        catchError((error) => {
          console.error(
            "Erreur lors de la récupération des diplome :",
            error
          );
          throw error;
        })
      );
    }
  }

  /**
  * Retrieve list of all required grade
  * 
  * @param {number, number}
  * @returns {Observable<IGradeGet[]>}
*/
  public getRequiredGrades(page = 0, limit = 2000): Observable<IResGradeGet> {
    if ((this.lastRequiredGradesLoad > 0) && this._requiredGrades$.value) {
      return of();
    } else {
    const params = new HttpParams().set("page", page).set("limit", limit);
    return this._http
      .get<IResGradeGet>(`${this.apiURL}/grade/required-grades`, { params })
      .pipe(
        tap((response) => {
          this._requiredGrades$.next(response.data);
            this.lastRequiredGradesLoad = Date.now();
        }),
        catchError((error) => {
          console.error(
            "Erreur lors de la récupération des etablissements :",
            error
          );
          throw error;
        })
      );
    }
  }

  /**
    * Retrieve list of all required grade
    * 
    * @param {number, number}
    * @returns {Observable<IGradeGet[]>}
  */
  public getAllGoals(page = 0, limit = 2000): Observable<IResGoalGet> {
    if ((this.lastGoalsLoad > 0) && this._goals$.value) {
      return of();
    } else {
    const params = new HttpParams().set("page", page).set("limit", limit);
    return this._http
      .get<IResGoalGet>(`${this.apiURL}/grade/required-grades`, { params })
      .pipe(
        tap((response) => {
          this._goals$.next(response.data);
            this.lastGoalsLoad = Date.now();
        }),
        catchError((error) => {
          console.error(
            "Erreur lors de la récupération des etablissements :",
            error
          );
          throw error;
        })
      );
    }
  }


  /**
  * Retrieve list of all interest
  * 
  * @param {number, number}
  * @returns {Observable<IGradeGet[]>}
*/
  public getAllInterest(page = 0, limit = 2000): Observable<IResInterestGet> {
    if ((this.lastInterestsLoad > 0) && this._interests$.value) {
      return of();
    } else {
    const params = new HttpParams().set("page", page).set("limit", limit);
    return this._http
      .get<IResInterestGet>(`${this.apiURL}/interests`, { params })
      .pipe(
        tap((response) => {
          this._interests$.next(response.data);
            this.lastInterestsLoad = Date.now();
        }),
        catchError((error) => {
          console.error(
            "Erreur lors de la récupération des intérêts :",
            error
          );
          throw error;
        })
      );
    }
  }

  /**
  * Retrieve list of all duration
  * 
  * @param {number, number}
  * @returns {Observable<IDurationGet[]>}
*/
  public getAllDuration(page = 0, limit = 2000): Observable<IResDurationGet> {
    if ((this.lastDurationsLoad > 0) && this._durations$.value) {
      return of();
    } else {
    const params = new HttpParams().set("page", page).set("limit", limit);
    return this._http
      .get<IResDurationGet>(`${this.apiURL}/durations/get-all`, { params })
      .pipe(
        tap((response) => {
          this._durations$.next(response.data.map(res => res.duration));
            this.lastDurationsLoad = Date.now();
        }),
        catchError((error) => {
          console.error(
            "Erreur lors de la récupération des durées :",
            error
          );
          throw error;
        })
      );
    }
  }
}
