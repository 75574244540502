import { Component, OnInit } from '@angular/core';
import { LoaderService } from '../../services/loader/loader.service';
import { AsyncPipe } from '@angular/common';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-loading',
  standalone: true,
  imports: [AsyncPipe],
  providers: [LoaderService],
  templateUrl: './loading.component.html',
  styleUrl: './loading.component.scss'
})
export class LoadingComponent {

  // isLoading: boolean

  isLoading = this.loaderService.httpProgress();


  constructor(private loaderService: LoaderService) {}

  // ngOnInit(): void {
  //   this.isLoading = this.loaderService.httpProgress();
  // }

}
