<div [class.dark-theme]="themeService.isDark()">
    <div>
        <router-outlet></router-outlet>
    </div>
    
    <!-- <footer class="footer">
        <app-footer *ngIf="!(location == '/login' || location == '/register' || location == '/coming-soon')"></app-footer>
    </footer> -->
    <!-- <app-theme-customizer></app-theme-customizer> -->
     <app-loading></app-loading>
</div>