<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-md-4 col-sm-4 col-lg-2">
                <div class="single-footer-widget mb-30">
                    <h3>Contactez-Nous</h3>
                    <ul class="contact-us-link">
                        <li>
                            <i class='bx bx-map'></i>
                            <a href="#" target="_blank">2750 Quadra Street Victoria Road, New York, United States</a>
                        </li>
                        <li>
                            <i class='bx bx-phone-call'></i>
                            <a href="tel:+11234567890">+237 620 237 237</a>
                        </li>
                        <li>
                            <i class='bx bx-envelope'></i>
                            <a href="mailto:hello&#64;ednuv.com">hello&#64;ednuv.com</a>
                        </li>
                    </ul>
                    <ul class="social-link">
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                    </ul>
                </div>
            </div>
            <div class="col-md-4 col-sm-4 col-lg-2">
                <div class="single-footer-widget mb-30">
                    <h3>A Propos de nous</h3>
                    <ul class="support-link">
                        <li><a routerLink="/">FAQ</a></li>
                        <li><a routerLink="/">Support</a></li>
                        <li><a routerLink="/">Politique d'utilisation</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-md-4 col-sm-4 col-lg-2">
                <div class="single-footer-widget mb-30">
                    <h3>Orientation</h3>
                    <ul class="support-link">
                        <li><a routerLink="/">FAQ</a></li>
                        <li><a routerLink="/">Support</a></li>
                        <li><a routerLink="/">Politique d'utilisation</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-md-4 col-sm-4 col-lg-2">
                <div class="single-footer-widget mb-30">
                    <h3>Métiers</h3>
                    <ul class="useful-link">
                        <li><a routerLink="/site/metiers/secteur-economique">Secteur économique </a></li>
                        <li><a routerLink="/site/metiers/domaines-activite">Découvrir les métiers par domaine d'activité </a></li>
                        <li><a routerLink="/site/metiers/affinite-metiers">Des Métiers selon mon affinité </a></li>
                        <li><a routerLink="/site/metiers/metiers-en-tension">Métiers en tension </a></li>
                    </ul>
                </div>
            </div>
            <div class="col-md-4 col-sm-4 col-lg-2">
                <div class="single-footer-widget mb-30">
                    <h3>Formations</h3>
                    <ul class="support-link">
                        <li><a routerLink="/">FAQ</a></li>
                        <li><a routerLink="/">Support</a></li>
                        <li><a routerLink="/">Politique d'utilisation</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-md-4 col-sm-4 col-lg-2">
                <div class="single-footer-widget mb-30">
                    <h3>Etablissements</h3>
                    <ul class="support-link">
                        <li><a routerLink="/">Domaines d'etude</a></li>
                        <li><a routerLink="/">Type d'établissement </a></li>
                        <li><a routerLink="/">zone géographique</a></li>
                        <li><a routerLink="/">Statut</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="footer-bottom-area">
        <div class="container">
            <div class="logo">
                <a routerLink="/" class="d-inline-block"><img src="assets/img/logo.png" alt="image"></a>
            </div>
            <p>© Copyright My Career Design </p>
        </div>
    </div>
</footer>

<ngx-scrolltop></ngx-scrolltop>