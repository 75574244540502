import { Component, OnInit } from '@angular/core';
import { ThemeCustomizerService } from '../../../../common/theme-customizer/theme-customizer.service';
import { RouterLink } from '@angular/router';

@Component({
    selector: 'app-homeeight-about',
    standalone: true,
    imports: [RouterLink],
    templateUrl: './homeeight-about.component.html',
    styleUrls: ['./homeeight-about.component.scss']
})
export class HomeeightAboutComponent implements OnInit {

    isToggled = false;
	
    constructor(
        public themeService: ThemeCustomizerService
    ) {
        this.themeService.isToggled$.subscribe(isToggled => {
            this.isToggled = isToggled;
        });
    }

    toggleTheme() {
        this.themeService.toggleTheme();
    }

    ngOnInit(): void {}

}