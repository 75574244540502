<!-- Header -->
<header class="header-area p-relative" style="z-index: 1000;">


        <!-- Top Header -->
        <div class="top-header">
                <div class="container">
                        <div class="row align-items-center">
                                <div class="col-lg-8">
                                        <ul class="top-header-contact-info">
                                                <li>
                                                        <div class="logo">
                                                                <a routerLink="/" class="d-inline-block"><img
                                                                                src="assets/img/logo.png"
                                                                                alt="image"></a>
                                                        </div>
                                                </li>
                                        </ul>
                                </div>

                                <div class="col-lg-4">
                                        <div class="top-header-btn">
                                                <a routerLink="/login" class="default-btn"><i
                                                                class='bx bx-log-in icon-arrow before'></i><span
                                                                class="label">Log In</span><i
                                                                class="bx bx-log-in icon-arrow after"></i></a>
                                        </div>
                                </div>
                        </div>
                </div>
        </div>

        <!-- Navbar -->
        <div class="navbar-area navbar-style-four shadow" [ngClass]="{'sticky': isSticky}"
                [class.dark-navbar-area]="themeService.isDark()">
                <div class="container-fluid">
                        <nav class="navbar navbar-expand-lg navbar-light bg-light" [class.active]="classApplied">
                               
                                <button class="navbar-toggler" type="button" (click)="toggleClass()">
                                        <span class="burger-menu">
                                                <span class="top-bar"></span>
                                                <span class="middle-bar"></span>
                                                <span class="bottom-bar"></span>
                                        </span>
                                </button>
                                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                                        <ul class="navbar-nav">
                                                <li class="menulink nav-item" (click)="displayMenu(1)"><a
                                                                href="javascript:void(0)" class="nav-link">A
                                                                Propos de nous <i class='bx bx-chevron-down'></i></a>

                                                </li>
                                                @if (itemLink === 1) {
                                                        <div class="d-block d-lg-none bg-secondary bg-opacity-10 p-5">
                                                                <h3 class="fw-bold">A Propos de Nous</h3>
                                                                <p class="my-2">Des informations sur l’enseignement
                                                                        secondaire et
                                                                        supérieur en lien avec les choix d'orientation
                                                                        que doivent faire les élèves tout au long de
                                                                        leur scolarité. </p>
                                                                <a class="d-flex align-items-center"
                                                                        routerLink="/site/a-proposs" (click)="itemLink = 0">
                                                                        <span class="m-0 mb-1 me-2">En savoir
                                                                                plus</span>
                                                                        <i class="bi bi-arrow-right me-2"
                                                                                style="font-size: 20px;"></i>
                                                                </a>
                                                        </div>
                                                }
                                                <li class="menulink nav-item" (click)="displayMenu(2)"><a
                                                                href="javascript:void(0)" class="nav-link">Orientation
                                                                <i class='bx bx-chevron-down'></i></a>

                                                </li>
                                                @if (itemLink === 2) {
                                                        <div class="d-block d-lg-none bg-secondary bg-opacity-10 p-5">
                                                                <h3 class="fw-bold">Orientation</h3>
                                                                <p class="my-2">Des informations sur l’enseignement
                                                                        secondaire et
                                                                        supérieur en lien avec les choix d'orientation
                                                                        que doivent faire les élèves tout au long de
                                                                        leur scolarité. </p>
                                                                <a class="d-flex align-items-center"
                                                                        routerLink="/site/orientation" (click)="itemLink = 0">
                                                                        <span class="m-0 mb-1 me-2">Voir l'Orientation</span>
                                                                        <i class="bi bi-arrow-right me-2"
                                                                                style="font-size: 20px;"></i>
                                                                </a>
                                                        </div>
                                                }
                                                <li class="menulink nav-item" (click)="displayMenu(3)"><a
                                                                href="javascript:void(0)" class="nav-link">Métiers <i
                                                                        class='bx bx-chevron-down'></i></a>

                                                </li>
                                                @if (itemLink === 3) {
                                                        <div class="d-block d-lg-none bg-secondary bg-opacity-10 p-5">
                                                                <h3 class="fw-bold">Métiers</h3>
                                                                <p class="my-2">Des informations sur l’enseignement
                                                                        secondaire et
                                                                        supérieur en lien avec les choix d'orientation
                                                                        que doivent faire les élèves tout au long de
                                                                        leur scolarité. </p>
                                                                <a class="d-flex align-items-center"
                                                                        routerLink="/site/metiers" (click)="itemLink = 0">
                                                                        <span class="m-0 mb-1 me-2">Voir les
                                                                                Métiers</span>
                                                                        <i class="bi bi-arrow-right me-2"
                                                                                style="font-size: 20px;"></i>
                                                                </a>
                                                        </div>
                                                }
                                                <li class="menulink nav-item" (click)="displayMenu(4)"><a
                                                                href="javascript:void(0)" class="nav-link">Formations <i
                                                                        class='bx bx-chevron-down'></i></a>

                                                </li>
                                                @if (itemLink === 4) {
                                                        <div class="d-block d-lg-none bg-secondary bg-opacity-10 p-5">
                                                                <h3 class="fw-bold">Formations</h3>
                                                                <p class="my-2">Des informations sur l’enseignement
                                                                        secondaire et
                                                                        supérieur en lien avec les choix d'orientation
                                                                        que doivent faire les élèves tout au long de
                                                                        leur scolarité. </p>
                                                                <a class="d-flex align-items-center"
                                                                        routerLink="/site/formations" (click)="itemLink = 0">
                                                                        <span class="m-0 mb-1 me-2">Voir les
                                                                                Formations</span>
                                                                        <i class="bi bi-arrow-right me-2"
                                                                                style="font-size: 20px;"></i>
                                                                </a>
                                                        </div> 
                                                }
                                                <li class="menulink nav-item" (click)="displayMenu(5)"><a
                                                                href="javascript:void(0)" class="nav-link">Etablissement
                                                                <i class='bx bx-chevron-down'></i></a>

                                                </li>
                                                @if (itemLink === 5) {
                                                        <div class="d-block d-lg-none bg-secondary bg-opacity-10 p-5">
                                                                <h3 class="fw-bold">Etablissements</h3>
                                                                <p class="my-2">Des informations sur l’enseignement
                                                                        secondaire et
                                                                        supérieur en lien avec les choix d'orientation
                                                                        que doivent faire les élèves tout au long de
                                                                        leur scolarité. </p>
                                                                <a class="d-flex align-items-center"
                                                                        routerLink="/site/etablissements" (click)="itemLink = 0">
                                                                        <span class="m-0 mb-1 me-2">Voir les
                                                                                établissements</span>
                                                                        <i class="bi bi-arrow-right me-2"
                                                                                style="font-size: 20px;"></i>
                                                                </a>
                                                        </div>   
                                                }
                                                <li class="menulink nav-item" (click)="displayMenu(6)"><a
                                                                href="javascript:void(0)" class="nav-link">Cap
                                                                vers l'emploi <i class='bx bx-chevron-down'></i></a>

                                                </li>
                                                @if (itemLink === 6) {
                                                        <div class="d-block d-lg-none bg-secondary bg-opacity-10 p-5">
                                                                <h3 class="fw-bold">Cap vers l'emploi</h3>
                                                                <p class="my-2">Des informations sur l’enseignement
                                                                        secondaire et
                                                                        supérieur en lien avec les choix d'orientation
                                                                        que doivent faire les élèves tout au long de
                                                                        leur scolarité. </p>
                                                                <a class="d-flex align-items-center"
                                                                        routerLink="/site/cap-vers-emploie" (click)="itemLink = 0">
                                                                        <span class="m-0 mb-1 me-2">Voir le Cap vers
                                                                                l'emploi</span>
                                                                        <i class="bi bi-arrow-right me-2"
                                                                                style="font-size: 20px;"></i>
                                                                </a>
                                                        </div>
                                                }
                                                <li class="menulink nav-item" (click)="displayMenu(7)"><a
                                                                href="javascript:void(0)" class="nav-link">Mobilité
                                                                internationale <i class='bx bx-chevron-down'></i></a>
                                                        <!-- <ul class="dropdown-menu">
                                                                <li class="nav-item"><a routerLink="/shop-grid"
                                                                                class="nav-link"
                                                                                routerLinkActive="active"
                                                                                [routerLinkActiveOptions]="{exact: true}">Shop
                                                                                Grid</a></li>
                                                                <li class="nav-item"><a
                                                                                routerLink="/shop-grid-fullwidth"
                                                                                class="nav-link"
                                                                                routerLinkActive="active"
                                                                                [routerLinkActiveOptions]="{exact: true}">Shop
                                                                                Grid
                                                                                FullWidth</a></li>
                                                                <li class="nav-item"><a routerLink="/single-products"
                                                                                class="nav-link"
                                                                                routerLinkActive="active"
                                                                                [routerLinkActiveOptions]="{exact: true}">Products
                                                                                Details</a></li>
                                                                <li class="nav-item"><a routerLink="/cart"
                                                                                class="nav-link"
                                                                                routerLinkActive="active"
                                                                                [routerLinkActiveOptions]="{exact: true}">Cart</a>
                                                                </li>
                                                                <li class="nav-item"><a routerLink="/checkout"
                                                                                class="nav-link"
                                                                                routerLinkActive="active"
                                                                                [routerLinkActiveOptions]="{exact: true}">Checkout</a>
                                                                </li>
                                                        </ul> -->
                                                </li>
                                                @if (itemLink === 7) {
                                                        <div class="d-block d-lg-none bg-secondary bg-opacity-10 p-5">
                                                                <h3 class="fw-bold">Mobilité internationale</h3>
                                                                <p class="my-2">Des informations sur l’enseignement
                                                                        secondaire et
                                                                        supérieur en lien avec les choix d'orientation
                                                                        que doivent faire les élèves tout au long de
                                                                        leur scolarité. </p>
                                                                <a class="d-flex align-items-center" (click)="itemLink = 0"
                                                                        routerLink="/site/mobilite-internationale">
                                                                        <span class="m-0 mb-1 me-2">Voir la Mobilité
                                                                                internationale</span>
                                                                        <i class="bi bi-arrow-right me-2"
                                                                                style="font-size: 20px;"></i>
                                                                </a>
                                                        </div>
                                                }
                                                <li class="menulink nav-item" (click)="displayMenu(8)"><a
                                                                href="javascript:void(0)" class="nav-link">Infos
                                                                et outils <i class='bx bx-chevron-down'></i></a>

                                                </li>
                                                @if (itemLink === 8) {
                                                        <div class="d-block d-lg-none bg-secondary bg-opacity-10 p-5">
                                                                <h3 class="fw-bold">Infos et outils</h3>
                                                                <p class="my-2">Des informations sur l’enseignement
                                                                        secondaire et
                                                                        supérieur en lien avec les choix d'orientation
                                                                        que doivent faire les élèves tout au long de
                                                                        leur scolarité. </p>
                                                                <a class="d-flex align-items-center"
                                                                        routerLink="/site/infos-et-outils" (click)="itemLink = 0">
                                                                        <span class="m-0 mb-1 me-2">Voir les Infos et
                                                                                outils</span>
                                                                        <i class="bi bi-arrow-right me-2"
                                                                                style="font-size: 20px;"></i>
                                                                </a>
                                                        </div>
                                                }
                                                <li class="menulink nav-item"><a routerLink="/contact" class="nav-link"
                                                                routerLinkActive="active"
                                                                [routerLinkActiveOptions]="{exact: true}">Contact</a>
                                                </li>
                                        </ul>
                                        <div id="menulink" class="menulink position-absolute w-100" (click)="onClickOutside($event)">
                                                @if(itemLink === 1){
                                                        <nav
                                                                class="position-absolute bg-white mt-5 w-100 top-50 shadow d-none d-lg-flex">
                                                                <div class="row p-0 m-0">
                                                                        <div class="col-6 bg-secondary bg-opacity-10 p-5">
                                                                                <h3 class="fw-bold">A Propos de Nous</h3>
                                                                                <p class="my-2">Des informations sur l’enseignement
                                                                                        secondaire et
                                                                                        supérieur en lien avec les choix d'orientation
                                                                                        que doivent faire les élèves tout au long de
                                                                                        leur scolarité. </p>
                                                                                <a class="d-flex align-items-center"
                                                                                        routerLink="/site/a-proposs" (click)="itemLink = 0">
                                                                                        <span class="m-0 mb-1 me-2">En savoir
                                                                                                plus</span>
                                                                                        <i class="bi bi-arrow-right me-2"
                                                                                                style="font-size: 20px;"></i>
                                                                                </a>
                                                                        </div>
                                                                        <div class="col-6 p-5"></div>
                                                                </div>
                
                                                        </nav>
                                                        }
                                                        @if(itemLink === 2){
                                                        <nav
                                                                class="position-absolute d-none d-lg-flex bg-white mt-5 w-100 top-50 shadow">
                                                                <div class="row p-0 m-0">
                                                                        <div class="col-6 bg-secondary bg-opacity-10 p-5">
                                                                                <h3 class="fw-bold">Orientation</h3>
                                                                                <p class="my-2">Des informations sur l’enseignement
                                                                                        secondaire et
                                                                                        supérieur en lien avec les choix d'orientation
                                                                                        que doivent faire les élèves tout au long de
                                                                                        leur scolarité. </p>
                                                                                <a class="d-flex align-items-center"
                                                                                        routerLink="/site/orientation" (click)="itemLink = 0">
                                                                                        <span class="m-0 mb-1 me-2">Voir l'Orientation</span>
                                                                                        <i class="bi bi-arrow-right me-2"
                                                                                                style="font-size: 20px;"></i>
                                                                                </a>
                                                                        </div>
                                                                        <div class="col-6 p-5"></div>
                                                                </div>
                
                                                        </nav>
                                                        }
                                                        @if(itemLink === 3){
                                                        <nav
                                                                class="position-absolute d-none d-lg-flex bg-white mt-5 w-100 top-50 shadow">
                                                                <div class="row p-0 m-0">
                                                                        <div class="col-6 bg-secondary bg-opacity-10 p-5">
                                                                                <h3 class="fw-bold">Métiers</h3>
                                                                                <p class="my-2">Des informations sur l’enseignement
                                                                                        secondaire et
                                                                                        supérieur en lien avec les choix d'orientation
                                                                                        que doivent faire les élèves tout au long de
                                                                                        leur scolarité. </p>
                                                                                <a class="d-flex align-items-center"
                                                                                        routerLink="/site/metiers" (click)="itemLink = 0">
                                                                                        <span class="m-0 mb-1 me-2">Voir les
                                                                                                Métiers</span>
                                                                                        <i class="bi bi-arrow-right me-2"
                                                                                                style="font-size: 20px;"></i>
                                                                                </a>
                                                                        </div>
                                                                        <div class="col-6 p-5"></div>
                                                                </div>
                
                                                        </nav>
                                                        }
                
                                                        @if(itemLink === 4){
                                                        <nav
                                                                class="position-absolute d-none d-lg-flex bg-white mt-5 w-100 top-50 shadow">
                                                                <div class="row p-0 m-0">
                                                                        <div class="col-6 bg-secondary bg-opacity-10 p-5">
                                                                                <h3 class="fw-bold">Formations</h3>
                                                                                <p class="my-2">Des informations sur l’enseignement
                                                                                        secondaire et
                                                                                        supérieur en lien avec les choix d'orientation
                                                                                        que doivent faire les élèves tout au long de
                                                                                        leur scolarité. </p>
                                                                                <a class="d-flex align-items-center"
                                                                                        routerLink="/site/formations" (click)="itemLink = 0">
                                                                                        <span class="m-0 mb-1 me-2">Voir les
                                                                                                Formations</span>
                                                                                        <i class="bi bi-arrow-right me-2"
                                                                                                style="font-size: 20px;"></i>
                                                                                </a>
                                                                        </div>
                                                                        <div class="col-6 p-5"></div>
                                                                </div>
                
                                                        </nav>
                                                        }
                
                                                        @if(itemLink === 5){
                                                        <nav
                                                                class="position-absolute d-none d-lg-flex bg-white mt-5 w-100 top-50 shadow">
                                                                <div class="row p-0 m-0">
                                                                        <div class="col-6 bg-secondary bg-opacity-10 p-5">
                                                                                <h3 class="fw-bold">Etablissements</h3>
                                                                                <p class="my-2">Des informations sur l’enseignement
                                                                                        secondaire et
                                                                                        supérieur en lien avec les choix d'orientation
                                                                                        que doivent faire les élèves tout au long de
                                                                                        leur scolarité. </p>
                                                                                <a class="d-flex align-items-center"
                                                                                        routerLink="/site/etablissements" (click)="itemLink = 0">
                                                                                        <span class="m-0 mb-1 me-2">Voir les
                                                                                                établissements</span>
                                                                                        <i class="bi bi-arrow-right me-2"
                                                                                                style="font-size: 20px;"></i>
                                                                                </a>
                                                                        </div>
                                                                        <div class="col-6 p-5"></div>
                                                                </div>
                
                                                        </nav>
                                                        }
                
                                                        @if(itemLink === 6){
                                                        <nav
                                                                class="position-absolute d-none d-lg-flex bg-white mt-5 w-100 top-50 shadow">
                                                                <div class="row p-0 m-0">
                                                                        <div class="col-6 bg-secondary bg-opacity-10 p-5">
                                                                                <h3 class="fw-bold">Cap vers l'emploi</h3>
                                                                                <p class="my-2">Des informations sur l’enseignement
                                                                                        secondaire et
                                                                                        supérieur en lien avec les choix d'orientation
                                                                                        que doivent faire les élèves tout au long de
                                                                                        leur scolarité. </p>
                                                                                <a class="d-flex align-items-center"
                                                                                        routerLink="/site/cap-vers-emploie" (click)="itemLink = 0">
                                                                                        <span class="m-0 mb-1 me-2">Voir le Cap vers
                                                                                                l'emploi</span>
                                                                                        <i class="bi bi-arrow-right me-2"
                                                                                                style="font-size: 20px;"></i>
                                                                                </a>
                                                                        </div>
                                                                        <div class="col-6 p-5"></div>
                                                                </div>
                
                                                        </nav>
                                                        }
                
                                                        @if(itemLink === 7){
                                                        <nav
                                                                class="position-absolute d-none d-lg-flex bg-white mt-5 w-100 top-50 shadow">
                                                                <div class="row p-0 m-0">
                                                                        <div class="col-6 bg-secondary bg-opacity-10 p-5">
                                                                                <h3 class="fw-bold">Mobilité internationale</h3>
                                                                                <p class="my-2">Des informations sur l’enseignement
                                                                                        secondaire et
                                                                                        supérieur en lien avec les choix d'orientation
                                                                                        que doivent faire les élèves tout au long de
                                                                                        leur scolarité. </p>
                                                                                <a class="d-flex align-items-center"
                                                                                        routerLink="/site/mobilite-internationale" (click)="itemLink = 0">
                                                                                        <span class="m-0 mb-1 me-2">Voir la Mobilité
                                                                                                internationale</span>
                                                                                        <i class="bi bi-arrow-right me-2"
                                                                                                style="font-size: 20px;"></i>
                                                                                </a>
                                                                        </div>
                                                                        <div class="col-6 p-5"></div>
                                                                </div>
                
                                                        </nav>
                                                        }
                
                                                        @if(itemLink === 8){
                                                        <nav
                                                                class="position-absolute d-none d-lg-flex bg-white mt-5 w-100 top-50 shadow">
                                                                <div class="row p-0 m-0">
                                                                        <div class="col-6 bg-secondary bg-opacity-10 p-5">
                                                                                <h3 class="fw-bold">Infos et outils</h3>
                                                                                <p class="my-2">Des informations sur l’enseignement
                                                                                        secondaire et
                                                                                        supérieur en lien avec les choix d'orientation
                                                                                        que doivent faire les élèves tout au long de
                                                                                        leur scolarité. </p>
                                                                                <a class="d-flex align-items-center"
                                                                                        routerLink="/site/infos-et-outils" (click)="itemLink = 0">
                                                                                        <span class="m-0 mb-1 me-2">Voir les Infos et
                                                                                                outils</span>
                                                                                        <i class="bi bi-arrow-right me-2"
                                                                                                style="font-size: 20px;"></i>
                                                                                </a>
                                                                        </div>
                                                                        <div class="col-6 p-5"></div>
                                                                </div>
                
                                                        </nav>
                                                        }
                                        </div>
                                        

                                </div>
                        </nav>
                </div>
        </div>
</header>

<!-------------------------------------------------------- A revoir plus tard --------------------------------------->

<!-------------------------------------------------------- A revoir plus tard Fin --------------------------------------->



<!-- Search Overlay -->
<div class="search-overlay" [class.active]="classApplied3" [class.dark-search-overlay]="themeService.isDark()">
        <div class="d-table">
                <div class="d-table-cell">
                        <div class="search-overlay-layer"></div>
                        <div class="search-overlay-layer"></div>
                        <div class="search-overlay-layer"></div>
                        <div class="search-overlay-close" (click)="toggleClass3()">
                                <span class="search-overlay-close-line"></span>
                                <span class="search-overlay-close-line"></span>
                        </div>
                        <div class="search-overlay-form">
                                <form>
                                        <input type="text" class="input-search" placeholder="Search here...">
                                        <button type="submit"><i class='bx bx-search-alt'></i></button>
                                </form>
                        </div>
                </div>
        </div>
</div>